<template>
  <!-- navigation:simple START -->
  <div class="main-navigation">

    <!-- back START -->
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        :class="[
          'code code-back',
          { 'code-back-special' : pageTitle === ' ' }
        ]"
      >
        <a
          v-if="ifNotWelcome"
          v-on:click="$router.go(-1)"
        >
          <img height="16" src="@/assets/images/icon-back-black@3x.png" />
        </a>
      </div>
    </transition>
    <!-- back END -->

    <!-- logo START -->
    <div class="logo logo-simple">
      <h1 v-html="pageTitle"></h1>
    </div>
    <!-- logo END -->

    <!-- cart:icon START -->
    <div class="cart-icon">
      <router-link :to="'/cart'" v-if="activateNav"></router-link>
    </div>
    <!-- cart:icon END -->
  </div>
  <!-- navigation:simple END -->
</template>

<script>
export default {
  name: 'purchase-navigation',
  props: {
    pageTitle: {
      type: String,
    },
  },
  data() {
    return {
      ifNotWelcome: true,
    };
  },
  computed: {
    activateNav() {
      return !!(sessionStorage.getItem('activateNav') && Boolean(sessionStorage.getItem('activateNav')) === true);
    },
  },
  created() {
    if (localStorage.getItem('welcomeScreen') && this.$route.name === 'login') {
      this.ifNotWelcome = false;
    }
  },
};
</script>
